<template>
    <v-dialog persistent max-width="764px" v-model="dialog">
        <v-card>
            <v-card-title>Save Changes</v-card-title>
            <v-card-text class="pb-0">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense class="text-subtitle-1" type="warning">
                            <span v-if="object.items.length > 0">Are you sure that you want to save all changes?</span>
                            <span v-if="object.items.length === 0">You did not update anything.</span>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-right pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text color="primary" :loading="loading" @click="save" v-if="object.items.length > 0">Save Changes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

import { stringifyJSON } from '@/utils/encodes'
export default {
    name: 'TimesheetItemsUpdateDialog',
    data() {
        return {
            dialog: false,
            loading: false,
            object: { items: [] },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.object = { items: [] }
            this.errors = {}
        },
        save: function() {
            this.loading = true
            this.$store.dispatch('epanel/report/updateReportTimesheetInfo', { timesheet: this.$route.params['id'], items: stringifyJSON(this.object.items) }).then((response) => {
                this.close()
                this.$emit('updated', { items: response.data.items, message: response.message })
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
        },
        updateObject: function(item) {
            this.object.items = item.items
        }
    }
}

</script>