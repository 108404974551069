<template>
	<div>
        <v-row v-if="!loading && !permissions.can_view">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Timesheet Info</p>
                <p class="text-title mb-0">You do not have permission to view timesheet info</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_view">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0 px-2">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <p class="text-h4 mb-0" v-text="object.project"></p>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-right" v-if="permissions.can_edit">
                                <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon color="primary" v-bind="attrs" v-on="on" @click="openUpdateDialog"><v-icon>mdi-pencil</v-icon></v-btn></template><span>Edit</span></v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Start Date" :text="object.start_date"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="End Date" :text="object.end_date"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="No. of Man-days" :text="object.man_day"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="CPF Rate (%)" :text="object.cpf_rate"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Total COS" :text="object.amount"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Total COS w CPF" :text="object.total_amount"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="elevation-0" :ripple="false" @click="openUpdateItemsDialog" v-if="permissions.can_items_edit && selectedItemIds.length > 0">Save Changes</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right" v-if="permissions.can_download">
                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click="openDownloadDialog">
                        <v-icon small left>mdi-download</v-icon> Download
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_list">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <hot-table ref="table" :settings="settings" :width="getTableWidth()"></hot-table>
                </v-col>
            </v-row>
        </template>
		<update-item-dialog ref="updateItemDialog" @updated="updateItems"></update-item-dialog>
		<update-dialog ref="updateDialog" @updated="updateObject"></update-dialog>
		<download-dialog ref="downloadDialog" @downloaded="openLoadingDialog"></download-dialog>
		<simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { HotTable } from '@handsontable/vue'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import DisplayLabel from '../../../shared/DisplayLabel.vue'
import TimesheetItemsUpdateDialog from './TimesheetItemsUpdateDialog'
import TimesheetUpdateDialog from './TimesheetUpdateDialog'
import TimesheetDownloadDialog from './TimesheetDownloadDialog'
import SimpleActionDialog from '../../../shared/SimpleActionDialog.vue'

export default {
	name: 'TimesheetInfo',
	components: {
        HotTable,
        messageNotifier: MessageNotifier,
        displayLabel: DisplayLabel,
        updateDialog: TimesheetUpdateDialog,
        downloadDialog: TimesheetDownloadDialog,
        updateItemDialog: TimesheetItemsUpdateDialog,
        simpleActionDialog: SimpleActionDialog,
	},
	data () {
		return {
			loading: false,
			selectedItems: {},
			selectedItemIds: [],
			object: {
                name: '',
                start_date: '',
                end_date: '',
                man_days: '',
                cpf_rate: ''
			},
            permissions: {
                can_view: false,
                can_edit: false,
                can_download: false,
                can_list: false,
                can_items_edit: false,
            },
            settings: {
                licenseKey: 'non-commercial-and-evaluation',
                data: [],
                height: 'auto',
                startRows: 250,
                rowHeaders: true,
                colHeaders: true,
                stretchH: 'all',
                fixedColumnsLeft: 3,
                columns: [
                    { data: 'project', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'work_date', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'outlet', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'start_time', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'end_time', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'total_hours', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'man_day', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'account', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'logistic_method', type: 'dropdown', source: ['One-Way', 'Two-Way'], readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'remarks', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'transport', type: 'numeric', editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'reimbursement', type: 'numeric', editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'incentive', type: 'numeric', editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'deduction', type: 'numeric', editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'amount', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'total_amount', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                ],
                afterChange: (changes) => {
                    if(changes && changes.length > 0){
                        const name = changes[0][1]
                        const selectedItem = this.settings.data[changes[0][0]]
                        const oldValue = changes[0][2], newValue = changes[0][3]

                        if(!selectedItem.id || name !== 'logistic_method' && isNaN(newValue)) {
                            return
                        }

                        if(this.selectedItemIds.indexOf(selectedItem.id) === -1){
                            this.selectedItemIds.push(selectedItem.id)
                            this.selectedItems[selectedItem.id] = {id: selectedItem.id}
                        }

                        if(oldValue !== newValue){
                            if(name !== 'logistic_method'){
                                this.selectedItems[selectedItem.id][changes[0][1]] = changes[0][3]
                            }else{
                                const value = changes[0][3] === 'One-Way'?'OW':'TW'
                                this.selectedItems[selectedItem.id][changes[0][1]] = value
                            }
                        }
                    }
                }
            },
		}
	},
	computed: {
        table() {
            return this.$refs.table
        },
        updateDialog() {
            return this.$refs.updateDialog
        },
        updateItemDialog() {
            return this.$refs.updateItemDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        },
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
        this.get()
	},
	methods: {
        get: function() {
			this.loading = true
			this.$store.dispatch('epanel/report/getReportTimesheetInfo', {timesheet: this.$route.params.id}).then((response) => {
                this.loading = false
                this.object = response.data.object
                this.settings.colHeaders = response.data.headers
                this.settings.data = response.data.items
                this.permissions = response.data.permissions
                this.updateHotTable()
			}).catch(() => {
				this.loading = false
			})
		},
		getTableWidth: function(){
            return window.innerWidth - 36
        },
        updateHotTable: function() {
            this.updateHotTableColumns()
            this.$refs.table.hotInstance.updateSettings(this.settings)
        },
        updateHotTableColumns: function() {
            const isEditable = !this.permissions.can_items_edit?true:false
            const columns = [8, 9, 10, 11, 12, 13]
            columns.forEach((column) => {
                this.settings.columns[column]['readOnly'] = isEditable
            })
        },
        openUpdateDialog: function() {
            if(this.permissions.can_edit){
                this.updateDialog.updateObject({project: this.object.project, cpf_rate:this.object.cpf_rate.replace('%', '')})
                this.updateDialog.open()
            }
        },
        openUpdateItemsDialog: function() {
            this.updateItemDialog.updateObject({items: this.cleanSelectedItems()})
            this.updateItemDialog.open()
        },
        openDownloadDialog: function() {
            if(this.permissions.can_download){
                this.downloadDialog.open()
            }
        },
        openLoadingDialog: function(){
            this.loadingDialog.open()
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateObject: function(item) {
            this.selectedItems = {}
            this.selectedItemIds = []
            this.$set(this, 'object', item.object)
            this.$set(this.settings, 'data', item.items)
            this.updateHotTable()
            this.openNotifier(item.message)
        },
        updateItems: function(item) {
            this.selectedItems = {}
            this.selectedItemIds = []
            this.$set(this.settings, 'data', item.items)
            this.updateHotTable()
            this.openNotifier(item.message)
        },
        cleanSelectedItems: function() {
            var items = []

            if(Object.keys(this.selectedItems).length === 0) {
                return items
            }

            for(const key in this.selectedItems){
                items.push(this.selectedItems[key])
            }
            return items
        }
	}
}

</script>