<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5 mb-3">Update Timesheet</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field dense readonly label="Project Name*" v-model="object.project"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field dense label="CPF Rate" :rules="rules.cpf_rate" :error-messages="errors.cpf_rate" v-model="object.cpf_rate"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

export default {
	name: 'TimesheetUpdateDialog',
    data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {},
			rules: {
				cpf_rate: [
                    (value) => {
                        if(isNaN(value)) {
                            return 'CPF rate must be numeric value'
                        }

                        return true
                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.reset()
			this.form.resetValidation()
            this.object = { project: '', cpf_rate: ''}
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = item
        },
		save: function() {
			if(this.form.validate()){
				this.loading = true
                this.$store.dispatch('epanel/report/updateReportTimesheet', { id: this.$route.params.id, object: { cpf_rate: this.object.cpf_rate } }).then((response) => {
                    var object = { object: response.data.object, items: response.data.items, message: response.message }
                    this.$emit('updated', object)
                    this.close()
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		}
	}
}

</script>